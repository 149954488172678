import React, { useState, useEffect } from "react";
import VitrinaDecants from "./components/VitrinaDecants";
import "./styles/general.css";
import "./styles/datatable.css";
import "./styles/carrito.css";
import "./styles/responsive.css";
import "bootstrap-icons/font/bootstrap-icons.css";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Esperar a que los recursos estáticos (CSS, fuentes) se carguen
    const handleLoad = () => {
      // Dar un pequeño margen para que las transiciones sean suaves
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    };

    // Si la página ya está cargada, ejecutar directamente
    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      // Si no, esperar al evento load
      window.addEventListener('load', handleLoad);
      return () => window.removeEventListener('load', handleLoad);
    }
  }, []);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="preloader" id="preloader">
          <p>Cargando...</p>
          {/* Puedes agregar aquí un spinner o animación */}
        </div>
      );
    }
    return <VitrinaDecants />;
  };

  return (
    <div className="App">
      <main className="app-main">{renderContent()}</main>
    </div>
  );
}

export default App;
